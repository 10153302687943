body {
    overflow-x: hidden;
}


.slider-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.slider-item {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
}


.slider-image {
    width: 100%;
    height: 723px;
    object-fit: cover; 
    display: block; 
}
.slider-image img{
    height: 100%;
    object-fit: cover;
}


.content {
    position: absolute;    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.owl-theme .owl-nav{
    width: 100%;
    position: absolute;
    top: 37%;
    display: flex;
    justify-content: space-between;
}

.owl-nav .owl-prev, .owl-nav .owl-next{
    width: 58px;
    aspect-ratio: 1/1;
    padding: 1em !important;
    background-color: rgba(255, 255,255, 0.8)!important;
    border-radius: 50% !important;
    opacity: 0.6;
    transition: all, .5s ease;
}

.owl-nav .owl-prev:hover, .owl-nav .owl-next:hover{
    /* background: none !important; */
    outline: none !important;
    opacity: 1;
}

.owl-prev span, .owl-next span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #524c4c;
}
.owl-prev span:hover, .owl-next span:hover{
    color: #fff;
}


@media screen and (max-width: 992px) {
    .slider-image{
        height: 540px;
    }
}

@media screen and (max-width: 767px) {
    .slider-image{
        height: 434px;
    }
}

@media screen and (max-width: 702px) {
    .slider-image{
        height: 355px;
    }
    .owl-nav .owl-prev, .owl-nav .owl-next{
        width: 37px;
    }
    .owl-prev span, .owl-next span{
        font-size: 31px;
    }
}

@media screen and (max-width: 576px) {
    .slider-image{
        height: 336px;
    }
}

@media screen and (max-width: 448px) {
    .slider-image{
        height: 288px;
    }
}

@media screen and (max-width: 368px) {
    .slider-image{
        height: 219px;
    }
}